<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Reporte de Aportes Detallado</strong>
          </CCardHeader>
          <CCardBody>

          <b-form id="Form" autocomplete="off" @submit.prevent="Validate">
            <b-row>
                <b-col md="6">
                  <b-form-group>
                    <label>Socio: </label>
                    <v-select placeholder="Seleccione un socio" class="w-100" :filterable="false" label="full_name" v-model="partner" @search="SearchPartner" :options="partners"></v-select>
                    <small v-if="errors.id_partner" class="form-text text-danger" >Seleccione un socio</small>
                  </b-form-group>
                </b-col>

                <b-col sm="2" >
                  <b-form-group label="Desde">
                    <b-form-select v-model="report.from" :options="years"></b-form-select>
                    <small v-if="errors.from" class="form-text text-danger" >Seleccione un año</small>
                  </b-form-group>
                </b-col>

                <b-col sm="2" >
                  <b-form-group label="Hasta">
                    <b-form-select v-model="report.to" :options="years"></b-form-select>
                    <small v-if="errors.to" class="form-text text-danger" >Seleccione un año</small>
                  </b-form-group>
                </b-col>

                <b-col sm="12" md="1">
                  <b-form-group label=".">
                    <b-button class="form-control" type="submit" variant="primary"><i class="fas fa-search"></i></b-button>
                  </b-form-group>
                </b-col>

                <b-col sm="12" md="1">
                  <b-form-group label=".">
                    <b-button class="form-control" type="button" variant="success" @click="ExportExcel"><i class="fas fa-file-excel"></i></b-button>
                  </b-form-group>
                </b-col>

                

            </b-row>
          </b-form>


              <div class="table-responsive mt-3">
              <table class="table table-hover table-bordered">
                <thead>
                  <tr>
                    <th class="text-center" colspan="19">REPORTE DE APORTES  ( {{report.from}} -  {{report.to}}) </th>
                  </tr>
                  <tr>
                    <th width="3%" class="text-center">#</th>
                    <th width="10%" class="text-center">N° Pago</th>
                    <th width="40%" class="text-center">Socio</th>
                    <th width="10%" class="text-center">Fecha</th>
                    <th width="7%" class="text-center">Aporte</th>
                    <th width="7%" class="text-center">Sepelio</th>
                    <th width="7%" class="text-center">Total</th>
                    <th width="7%" class="text-center">Estado</th>
                  </tr>
                </thead>
                <tbody >
                  <tr v-for="(item, it) in data_table" :key="it">
                    <td class="text-center">{{ parseFloat(it) + 1}}</td>
                    <td class="text-center">{{item.number}}</td>
                    <td class="text-left">{{item.partner_name + " - " + item.partner_document_number}}</td>
                    <td class="text-left">{{item.description}}</td>
                    <td class="text-right">{{item.contribution }}</td>
                    <td class="text-right">{{item.burial}}</td>
                    <td class="text-right">{{item.total}}</td>
                    <td class="text-center">
                      <b-badge v-if="item.state == 1" variant="primary">Aportado</b-badge>
                      <b-badge v-if="item.state == 2" variant="warning">Pendiente</b-badge>
                      <b-badge v-if="item.state == 3" variant="success">Cancelado</b-badge>
                    </td>
                  </tr>
                </tbody>
               
              </table>
            </div>

  
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <LoadingComponent :is-visible="isLoading"/>
  </div>
</template>

<script>
import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import "vue-select/src/scss/vue-select.scss";

const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState } from "vuex";
import CodeToName from "@/assets/js/CodeToName";
var moment = require("moment");
import LoadingComponent from './../pages/Loading'

export default {
  name: "UsuarioList",
  components:{
    vSelect,
    LoadingComponent,
  },
  data() {
    return {
      isLoading: false,
      module: 'ReportContributionDetail',
      role:1,
      perPage: 15,
      currentPage: 1,
      rows: 0,
      data_table: [],


      report:{
        id_partner:'',
        from:moment(new Date()).local().format("YYYY"),
        to:moment(new Date()).local().format("YYYY"),
      },
      partners : [],
      partner: null,
      years:[
          { value: '1990', text:'1990'},
          { value: '1991', text:'1991'},
          { value: '1992', text:'1992'},
          { value: '1993', text:'1993'},
          { value: '1994', text:'1994'},
          { value: '1995', text:'1995'},
          { value: '1996', text:'1996'},
          { value: '1997', text:'1997'},
          { value: '1998', text:'1998'},
          { value: '1999', text:'1999'},
          { value: '2000', text:'2000'},
          { value: '2001', text:'2001'},
          { value: '2002', text:'2002'},
          { value: '2003', text:'2003'},
          { value: '2004', text:'2004'},
          { value: '2005', text:'2005'},
          { value: '2006', text:'2006'},
          { value: '2007', text:'2007'},
          { value: '2008', text:'2008'},
          { value: '2009', text:'2009'},
          { value: '2010', text:'2010'},
          { value: '2011', text:'2011'},
          { value: '2012', text:'2012'},
          { value: '2013', text:'2013'},
          { value: '2014', text:'2014'},
          { value: '2015', text:'2015'},
          { value: '2016', text:'2016'},
          { value: '2017', text:'2017'},
          { value: '2018', text:'2018'},
          { value: '2019', text:'2019'},
          { value: '2020', text:'2020'},
          { value: '2021', text:'2021'},
          { value: '2022', text:'2022'},
          { value: '2023', text:'2023'},
          { value: '2024', text:'2024'},
          { value: '2025', text:'2025'},
          { value: '2026', text:'2026'},
          { value: '2027', text:'2027'},
          { value: '2028', text:'2028'},
          { value: '2029', text:'2029'},
          { value: '2030', text:'2030'},
        ],


      errors:{
        id_partner:false,
        to:false,
        from:false,
      }
    };
  },
  mounted() {
   
  },
  methods: {
    SearchPartner,
    Validate,
    Report,
    ExportExcel,
  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    id_establishment: function () {
      let id_establishment = window.localStorage.getItem("id_establishment");
      id_establishment = JSON.parse(je.decrypt(id_establishment));
      return id_establishment;
    },
  },
};

function SearchPartner(search, loading) {
  
   let me = this;
    let url = this.url_base + "search-partner/" + search;
    if (search !== "") {
      loading(true);
      axios({
        method: "GET",
        url: url,
      }).then(function (response) {
            me.partners = response.data;
            loading(false);
      })
    }
}


function ExportExcel() {  
  this.errors.id_partner = this.partner == null ? true : false;
  this.errors.from = this.report.from.length == 0 ? true : false;
  this.errors.to = this.report.to.length == 0 ? true : false;

 if (this.errors.id_partner == true) { return false; }
 if (this.errors.from == true) { return false; }
 if (this.errors.to == true) { return false; }
    
  let me = this;
  let url = me.url_base + "report-contribution-detail-excel/"+me.partner.id+"/"+me.report.from+"/"+me.report.to;
  window.open(url,'_blank');
}

function Validate() {
  
  this.errors.id_partner = this.partner == null ? true : false;
  this.errors.from = this.report.from.length == 0 ? true : false;
  this.errors.to = this.report.to.length == 0 ? true : false;

  if (this.errors.id_partner == true) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.from == true) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.to == true) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }

  this.Report();
}


function Report() {
  let me = this;
  me.report.id_partner = me.partner.id;
  let data = me.report;
  let url = this.url_base + "report/contribution-detail";
  me.isLoading = true;
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: { token: this.token, module: this.module, role: 1,},
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.data_table = response.data.result;

      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
      me.isLoading = false;
    })
     .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = false;
    });
}





</script>
